import { Image, ModalStatus, Span, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import { enqueueSnackbar } from "notistack";
import transparentPixelImage from "public/assets/images/transparent-pixel.png";
import { useCallback, useEffect, useRef, useState } from "react";

import { type HcfTimeclockComplianceVideoConfig } from "../../ShiftState/types";
import { ClockActionPictureDialogEvent } from "../ClockActionPictureDialogEvent";
import startRecordingButtonImage from "../images/start_recording_button.svg";
import stopRecordingButtonImage from "../images/stop_recording_button.svg";
import { ConfirmClockVideoDeletionDialog } from "./ConfirmClockVideoDeletionDialog";
import { useVideoRecorder } from "./useVideoRecorder";
import { TimeclockVideoPreviewContent } from "./VideoPreviewContent";

interface RecordVideoDialogProps {
  videoConfig: HcfTimeclockComplianceVideoConfig | undefined;
  logData: Record<string, unknown>;
  onClose: () => void;
  videoConfirmedCallback: (blob: Blob) => void;
}

export function RecordVideoDialog(props: RecordVideoDialogProps) {
  const { videoConfig, logData, onClose, videoConfirmedCallback } = props;
  const modalState = useModalState(ModalStatus.OPEN);
  const confirmDeletionModalState = useModalState(ModalStatus.CLOSED);

  const [videoPreviewElementRef, setVideoPreviewElementRef] = useState<HTMLVideoElement>();
  const lastReceivedErrorRef = useRef<string>();

  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string>();
  const videoBlobRef = useRef<Blob>();

  const onVideoRecorded = useCallback((blob: Blob) => {
    videoBlobRef.current = blob;
    setRecordedVideoUrl(URL.createObjectURL(blob));
  }, []);

  const logTimeclockEvent = useCallback(
    (eventAction: ClockActionPictureDialogEvent, meta?: Record<string, any>) => {
      logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
        ...logData,
        ...meta,
        eventAction,
      });
    },
    [logData]
  );

  const {
    startRecording,
    stopRecording,
    isReady,
    isRecording,
    recordingTime,
    isErrored: isVideoErrored,
    errorMessage: videoInitErrorMessage,
  } = useVideoRecorder({
    videoPreviewRef: videoPreviewElementRef,
    onVideoRecorded,
    maxRecordingTime: videoConfig?.maxVideoLength ?? 60,
    resolution: {
      width: videoConfig?.videoWidth ?? 414,
      height: videoConfig?.videoHeight ?? 736,
    },
    videoBitsPerSecond: videoConfig?.videoBitRate,
  });

  useEffect(() => {
    if (!videoInitErrorMessage || videoInitErrorMessage === lastReceivedErrorRef.current) {
      return;
    }

    lastReceivedErrorRef.current = videoInitErrorMessage;
    logError(APP_V2_APP_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_ERROR, {
      error: new Error(videoInitErrorMessage),
      metadata: { logData, location: "init-or-start-recording" },
    });
    logTimeclockEvent(ClockActionPictureDialogEvent.RECORDING_FAILURE, {
      errorMessage: videoInitErrorMessage,
      recordingTime,
      isRecording,
    });
  }, [videoInitErrorMessage, isRecording, logData, recordingTime, logTimeclockEvent]);

  return (
    <>
      {recordedVideoUrl ? (
        <Dialog fullScreen open={modalState.modalIsOpen}>
          <DialogTitle mt={2} mb={1}>
            Looks good?
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} display="flex" textAlign="center">
              <Text>
                This video will be reviewed by facility admin.
                <br />
                <Span sx={{ color: (theme) => theme.palette.error.main }}>
                  Repeated failure to use the time clock may result in DNRs or pay reversals.
                </Span>
              </Text>
              <Box>
                <TimeclockVideoPreviewContent recordedVideoUrl={recordedVideoUrl} />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ paddingBottom: 1 }}>
            <Stack spacing={1} direction="column" width="100%">
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  videoConfirmedCallback(videoBlobRef.current!);
                }}
              >
                Looks great! Submit video
              </Button>
              <Button fullWidth variant="text" onClick={confirmDeletionModalState.openModal}>
                Try again
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={modalState.modalIsOpen}
          sx={{ background: "black" }}
          PaperProps={{ sx: { backgroundColor: "black" } }}
        >
          <DialogContent sx={{ display: "flex", position: "relative", padding: 0 }}>
            <video
              ref={(ref) => {
                setVideoPreviewElementRef(ref ?? undefined);
              }}
              playsInline
              autoPlay
              muted
              style={{
                background: "black",
                objectFit: "contain",
                width: "100%",
                height: "100%",
              }}
              // To prevent android from showing askewed play icon until video loads
              poster={transparentPixelImage}
            />
            {isVideoErrored && (
              <Text
                align="center"
                color="white"
                position="absolute"
                width="100%"
                top="40%"
                sx={{ margin: "auto" }}
              >
                {videoInitErrorMessage}
              </Text>
            )}
            <IconButton
              disableRipple
              sx={{ position: "absolute", top: "2vh", left: "1.5vh" }}
              title="Go back"
              onClick={() => {
                logTimeclockEvent(ClockActionPictureDialogEvent.RECORDING_CANCEL, {
                  recordingTime,
                  isRecording,
                });

                onClose();
              }}
            >
              <ArrowBackIcon sx={{ fontSize: "4vh", color: "white" }} />
            </IconButton>
            <Stack
              spacing={0}
              sx={{
                position: "absolute",
                bottom: "3vh",
                left: 0,
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text color="white">
                {isDefined(recordingTime)
                  ? `0:${recordingTime.toString().padStart(2, "0")}`
                  : "-:--"}
              </Text>

              <IconButton
                disableRipple
                title={isRecording ? "Stop recording" : "Start recording"}
                onClick={() => {
                  if (!isReady || recordedVideoUrl) {
                    return;
                  }

                  if (isRecording) {
                    if (
                      isDefined(videoConfig?.minVideoLength) &&
                      isDefined(recordingTime) &&
                      recordingTime < videoConfig.minVideoLength
                    ) {
                      enqueueSnackbar({
                        variant: "warning",
                        message: `Video must be at least ${videoConfig.minVideoLength}s long.`,
                      });
                      return;
                    }

                    void stopRecording();
                    logTimeclockEvent(ClockActionPictureDialogEvent.RECORDING_DONE, {
                      recordingTime,
                    });
                    return;
                  }

                  void startRecording();
                  logTimeclockEvent(ClockActionPictureDialogEvent.RECORDING_START);
                }}
              >
                <Image
                  src={isRecording ? stopRecordingButtonImage : startRecordingButtonImage}
                  alt={isRecording ? "Stop recording" : "Start recording"}
                  width="8vh"
                  height="8vh"
                />
              </IconButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      <ConfirmClockVideoDeletionDialog
        modalState={confirmDeletionModalState}
        onContinue={() => {
          setRecordedVideoUrl(undefined);
          confirmDeletionModalState.closeModal();
        }}
        onCancel={() => {
          confirmDeletionModalState.closeModal();
        }}
      />
    </>
  );
}
